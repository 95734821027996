import React, { useState, useEffect, useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { Section } from '@atlaskit/menu';
import { MODAL_ID as DASHBOARD_MODAL_ID } from '@atlassian/jira-dashboard-common/src/constants.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { UI_EVENT_TYPE } from '@atlassian/jira-product-analytics-bridge';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useDashboardAnalytics } from '../../../../../controllers/dashboard-analytics/main.tsx';
import { useDropdownItemOnClickHandler } from '../../../../../utils/dropdown-item.tsx';
import messages from './messages';

const EMPTY_WALLBOARD_URL = '/';

export const fetchWallboardUrl = async () => {
	try {
		const response = await fetch('/rest/wallboard/latest/slideshow/url', {
			method: 'GET',
		});
		if (!response.ok) {
			throw new Error(`Error server response: ${response.status}`);
		}
		return response.text();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// if error, then the link would be grayed out
		fireErrorAnalytics({
			error,
			meta: {
				id: 'viewWallboardSlideshow',
				packageName: 'jiraDashboardInternalCommon',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
		return EMPTY_WALLBOARD_URL;
	}
};

export const WallboardSlideshow = () => {
	const { isAnonymous } = useTenantContext();

	const [wallboardSlideshowUrl, setWallboardSlideshowUrl] = useState<string | undefined>(undefined);
	const [, { on: modalWallboardOpen }] = useSwitchModals(DASHBOARD_MODAL_ID.CONFIGURE_WALLBOARD);
	const fireAnalytics = useDashboardAnalytics();

	const configureWallboardSlideShowClickHandler = useCallback(() => {
		fireAnalytics({
			eventType: UI_EVENT_TYPE,
			actionSubjectId: 'dashboard',
			actionSubject: 'wallboard-slideshow.configure',
			action: 'clicked',
		});
		// Wrapping modalWallboardOpen() inside setTimeout
		// for the focus to be on the dropdown button before opening
		// the modal as part of accessibility ticket DAG-903
		setTimeout(() => {
			modalWallboardOpen();
		}, 1);
	}, [fireAnalytics, modalWallboardOpen]);

	const { formatMessage } = useIntl();
	const { dropdownItemClickHandler } = useDropdownItemOnClickHandler();

	useEffect(() => {
		if (!isAnonymous && wallboardSlideshowUrl === undefined) {
			fetchWallboardUrl().then((url) => {
				setWallboardSlideshowUrl(url || EMPTY_WALLBOARD_URL);
			});
		}
	}, [isAnonymous, wallboardSlideshowUrl]);

	const isViewWallboardSlideshowEnabled =
		wallboardSlideshowUrl !== EMPTY_WALLBOARD_URL && !isAnonymous;
	// `wallboardSlideShowUrl` can be undefined and is generated through a fetch call which may be fail,
	// so we have `EMPTY_WALLBOARD_URL` as a fallback
	const wallboardSlideshowUrlWithFallback = wallboardSlideshowUrl || EMPTY_WALLBOARD_URL;
	return (
		<Section hasSeparator>
			<DropdownItem
				data-testid="dashboard-internal-common.ui.dashboard-content.header.action-dropdown.wallboard-slideshow.wallboard-slideshow"
				isDisabled={!isViewWallboardSlideshowEnabled}
				href={wallboardSlideshowUrlWithFallback}
				onClick={(e) =>
					dropdownItemClickHandler(
						e,
						wallboardSlideshowUrlWithFallback,
						'dashboard.view-wallboard-slideshow-item',
					)
				}
				UNSAFE_shouldDisableRouterLink
			>
				{formatMessage(messages.wallboardSlideshow)}
			</DropdownItem>
			{!isAnonymous && (
				<DropdownItem
					data-testid="dashboard-internal-common.ui.dashboard-content.header.action-dropdown.wallboard-slideshow.configure-wallboard"
					onClick={configureWallboardSlideShowClickHandler}
				>
					{formatMessage(messages.wallboardSetup)}
				</DropdownItem>
			)}
		</Section>
	);
};
