import noop from 'lodash/noop';
import type { GadgetData } from '@atlassian/jira-dashboard-common/src/types.tsx';
import { useGadgetTitle } from '../../utils/use-gadget-title';
import { useConfigurationStatus } from '../configuration-status';

export type GadgetInstrumentation = Partial<{
	// Reserved future use
}>;

export type GadgetReadOnlyControllerArg = Pick<
	GadgetData,
	| 'customisedTitle'
	| 'title'
	| 'configurable'
	| 'hasNonHiddenUserPrefs'
	| 'renderedGadgetUrl'
	| 'userPrefs'
	| 'amdModule'
	| 'reactKey'
> & {
	instrumentation?: GadgetInstrumentation;
};

export const useGadgetReadOnlyController = ({
	customisedTitle,
	title: defaultTitle,
	configurable,
	hasNonHiddenUserPrefs,
	renderedGadgetUrl,
	userPrefs,
}: GadgetReadOnlyControllerArg) => {
	const { isUnconfigured } = useConfigurationStatus({
		configurable,
		hasNonHiddenUserPrefs,
		renderedGadgetUrl,
		userPrefs,
	});

	const { title, setDefaultTitle, setTitle } = useGadgetTitle({
		defaultTitle,
		title: customisedTitle,
	});

	return {
		isUnconfigured,
		isEditModeEnabled: false,
		isEditButtonDisabled: true,
		isInEditMode: false,
		isConnectDisabled: false,
		onEditModeTrigger: noop,
		onEditModeCancel: noop,
		title,
		isRenaming: false,
		setDefaultTitle,
		onRenameTrigger: noop,
		onRenameSave: setTitle,
		onRenameCancel: noop,
		setPreferences: () => null,
	};
};
