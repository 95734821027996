import { useEffect, useState } from 'react';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useDashboardAMDModulesResource } from '@atlassian/jira-router-resources-dashboard';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state/index.tsx';

export const RouteResourceValidator = () => {
	const [{ isInitialRender }] = useSpaStateTransition();
	const { promise, loading, data, error } = useDashboardAMDModulesResource();
	const [hasRendered, setHasRendered] = useState(false);

	useEffect(() => {
		if (!loading) {
			setHasRendered(true);
		}
	}, [loading]);

	useEffect(() => {
		if (__SERVER__) {
			return;
		}

		const prefix = `Resource validation [${isInitialRender ? 'initial' : 'transition'}][${
			hasRendered ? 'rerender' : 'original'
		}]`;

		if (!promise) {
			logger.safeErrorWithoutCustomerData(
				'spa-apps.dashboard.route-resource-validate',
				`${prefix} failed, AMD resource may be missing`,
			);
		} else if (loading) {
			logger.safeInfoWithoutCustomerData(
				'spa-apps.dashboard.route-resource-validate',
				`${prefix} loading`,
			);
		} else if (data) {
			const { amdModules, sharedValue } = data;

			if (sharedValue == null || sharedValue instanceof Error) {
				logger.safeErrorWithoutCustomerData(
					'spa-apps.dashboard.route-resource-validate',
					`${prefix} failed, AMD resource shared value [${String(sharedValue)}]`,
				);
			} else if (!sharedValue?.isPreloaded && isInitialRender && !hasRendered) {
				logger.safeErrorWithoutCustomerData(
					'spa-apps.dashboard.route-resource-validate',
					`${prefix} failed, config+wrm resource did not run in SSR`,
				);
			} else if (amdModules === null) {
				logger.safeErrorWithoutCustomerData(
					'spa-apps.dashboard.route-resource-validate',
					`${prefix} failed, WRM resources failed to load`,
				);
			} else {
				const [failedCount, totalCount] = Object.values(amdModules).reduce(
					([f], v, i, arr) => [f + (v == null ? 1 : 0), arr.length],
					[0, 0],
				);

				if (failedCount > 0) {
					const failedNames = Object.entries(amdModules)
						.filter(([, v]) => v == null)
						.map(([k]) => k)
						.join(',');

					logger.safeErrorWithoutCustomerData(
						'spa-apps.dashboard.route-resource-validate',
						`${prefix} failed, AMD evaluation failed [${failedCount}] of [${totalCount}], failed modules [${failedNames}]`,
					);
				} else {
					logger.safeInfoWithoutCustomerData(
						'spa-apps.dashboard.route-resource-validate',
						`${prefix} success`,
					);
				}
			}
		} else if (error) {
			logger.safeErrorWithoutCustomerData(
				'spa-apps.dashboard.route-resource-validate',
				`${prefix} failed with AMD resource error [${
					error instanceof Error ? error.message : String(error)
				}]`,
				error,
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [promise, loading, data, error]);

	return null;
};
