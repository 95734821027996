import { useState, useEffect } from 'react';
import type { GadgetData } from '../types';
import { isDeprecatedGadget } from './is-deprecated-gadget';

export const useDashboardHasDeprecatedGadget = (gadgets: GadgetData[]) => {
	const [dashboardHasDeprecatedGadget, setDashboardHasDeprecatedGadget] = useState(false);
	useEffect(() => {
		setDashboardHasDeprecatedGadget(checkDashboardHasDeprecatedGadget(gadgets));
	}, [gadgets]);
	return { dashboardHasDeprecatedGadget };
};

const checkDashboardHasDeprecatedGadget = (gadgets: GadgetData[]): boolean => {
	return gadgets.some((gadget) => isDeprecatedGadget(gadget.amdModule));
};
