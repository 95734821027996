import React from 'react';
import Button from '@atlaskit/button';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { GadgetErrorType } from '@atlassian/jira-dashboard-common/src/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useGadgetRefresh } from '../../../controllers/gadget/context.tsx';
import { GadgetFallback } from '../gadget-fallback';
import messages from './messages';
import { MESSAGES_BY_ERROR, IS_REFRESH_BY_ERROR } from './utils';

export type GadgetErrorFallbackBaseProps = {
	id: string;
	message: string;
	isRefresh?: boolean;
};

export type GadgetErrorFallbackProps = {
	id: string;
	errorType: GadgetErrorType;
};

export const GadgetErrorFallbackBase = ({
	id,
	message,
	isRefresh = false,
}: GadgetErrorFallbackBaseProps) => {
	const { formatMessage } = useIntl();
	const onRefreshClick = useGadgetRefresh();

	return (
		<GadgetFallback
			id={id}
			icon={
				<WarningIcon
					label={formatMessage(messages.errorTitle)}
					primaryColor={token('color.text.warning', colors.Y300)}
				/>
			}
			action={
				isRefresh && (
					<Button appearance="default" spacing="default" onClick={onRefreshClick}>
						{formatMessage(messages.refreshButtonText)}
					</Button>
				)
			}
		>
			{message}
		</GadgetFallback>
	);
};

export const GadgetErrorFallback = ({ id, errorType }: GadgetErrorFallbackProps) => {
	const { formatMessage } = useIntl();

	return (
		<GadgetErrorFallbackBase
			message={formatMessage(MESSAGES_BY_ERROR[errorType])}
			isRefresh={IS_REFRESH_BY_ERROR[errorType]}
			id={id}
		/>
	);
};
