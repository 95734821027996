import React from 'react';
import { isReactKey } from '@atlassian/jira-react-gadgets-definition/src/main.tsx';
import {
	useLocalStorageGadgetHeight,
	useOnGadgetRender,
} from '../../../controllers/above-the-fold/main';
import { useGadgetDataContextEnhancer } from '../../../controllers/gadget-data';
import { isConnectGadget } from '../../../utils/gadget-type.tsx';
import { Amd } from './amd/main.tsx';
import { Connect } from './connect';
import { Forge } from './forge';
import { InlineHtml } from './inline-html';
import { ReactGadget } from './react';
import type { GadgetInnerProps } from './types';

export const GadgetInner = ({
	dashboardId,
	data,
	isUnconfigured,
	isEditModeEnabled,
	isLoading,
	isInEditMode,
	setIsLoading,
	setDefaultTitle,
	setTitle,
	shouldUseCache,
	isMaximized,
	onEditModeCancel,
	unconfiguredFallback,
	onRegisterConnectGadget,
	onUnregisterConnectGadget,
}: GadgetInnerProps) => {
	const enhancedContext = useGadgetDataContextEnhancer(data.context);
	const onGadgetRender = useOnGadgetRender(data.id);
	const unconfiguredModernGadgetInViewMode =
		!isInEditMode && isUnconfigured && !isConnectGadget(data);
	const localStorageHeight = useLocalStorageGadgetHeight(data.id);

	if (unconfiguredFallback != null && unconfiguredModernGadgetInViewMode) {
		return unconfiguredFallback;
	}

	if (isConnectGadget(data)) {
		return (
			<Connect
				dashboardId={dashboardId}
				gadgetId={data.id}
				inlineHtml={data.inlineHtml}
				isLoading={isLoading}
				isInEditMode={isInEditMode}
				setIsLoading={setIsLoading}
				onEditModeCancel={onEditModeCancel}
				onRegister={onRegisterConnectGadget}
				onUnregister={onUnregisterConnectGadget}
				setTitle={setTitle}
			/>
		);
	}

	if (data.amdModule != null) {
		return (
			<Amd
				dashboardId={dashboardId}
				gadgetId={data.id}
				amdModule={data.amdModule}
				context={enhancedContext}
				defaultTitle={data.title}
				userPrefs={data.userPrefs}
				inlineHtml={data.inlineHtml}
				isEditable={isEditModeEnabled}
				isLoading={isLoading}
				isInEditMode={isInEditMode}
				setIsLoading={setIsLoading}
				setDefaultTitle={setDefaultTitle}
				shouldUseCache={shouldUseCache}
				isMaximized={isMaximized}
				onEditModeCancel={onEditModeCancel}
			/>
		);
	}

	if (data.inlineHtml != null && data.renderedGadgetUrl == null) {
		return (
			<InlineHtml
				id={data.id}
				inlineHtml={data.inlineHtml}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
			/>
		);
	}

	if (data.forge != null) {
		return (
			<Forge
				forgeKey={data.forge.key}
				gadgetData={data}
				isInEditMode={isInEditMode}
				onEditModeCancel={onEditModeCancel}
				setIsLoading={setIsLoading}
			/>
		);
	}

	if (isReactKey(data.reactKey)) {
		return (
			<ReactGadget
				id={data.id}
				reactKey={data.reactKey}
				onEditModeCancel={onEditModeCancel}
				isInEditMode={isInEditMode}
				userPrefs={data.userPrefs}
				onRenderComplete={onGadgetRender}
				setDefaultTitle={setDefaultTitle}
				customSkeletonHeightInPx={localStorageHeight}
			/>
		);
	}

	throw new Error(`Unrecognised gadget type [${data.title}, ${data?.gadgetSpecUrl}]`);
};
