import type LoggerType from '@atlassian/jira-common-util-logging/src/log.tsx';
import { DEPENDENCIES, type DEPENDENCIES as DependenciesType } from '../constants';
import DegenerateEventTarget from './degenerate-event-target';

const formatGlobals = (deps: typeof DependenciesType) =>
	deps.map((dep) => `window.${dep}`).join(', ');

// IMPORTANT - EventTarget does not exist in SSR so a degenerate version is required so that it doesn't fail SSR.
// This class should not be run on SSR as it will fall back to the degenerate version.
const EventTargetUniversal =
	typeof EventTarget === 'undefined'
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(DegenerateEventTarget as unknown as typeof EventTarget)
		: EventTarget;

export default class GlobalsPolling extends EventTargetUniversal {
	isReady = false;

	isTimedOut = false;

	missingDeps: typeof DependenciesType = [];

	#intervalID: ReturnType<typeof setTimeout> | null = null;

	#timeoutID: ReturnType<typeof setTimeout> | null = null;

	#startTime: number = Date.now();

	constructor(pollingMs: number, timeoutMs: number, logger: typeof LoggerType) {
		super();

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		this.missingDeps = DEPENDENCIES.filter((dep) => !window[dep]);

		if (this.missingDeps.length === 0) {
			logger.safeInfoWithoutCustomerData(
				'spa-apps.dashboard.common',
				`all globals [${formatGlobals(DEPENDENCIES)}] ready on initialisation`,
			);
			this.isReady = true;
		} else {
			logger.safeWarnWithoutCustomerData(
				'spa-apps.dashboard.common',
				`missing globals [${formatGlobals(this.missingDeps)}]`,
			);
			this.#constructPolling(pollingMs, timeoutMs, logger);
		}
	}

	#constructPolling = (pollingMs: number, timeoutMs: number, logger: typeof LoggerType) => {
		this.#intervalID = setInterval(() => {
			const newReadyDeps: typeof DependenciesType = DEPENDENCIES.filter(
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				(dep) => window[dep] && this.missingDeps.includes(dep),
			);

			if (newReadyDeps.length !== 0) {
				this.missingDeps = this.missingDeps.filter((dep) => !newReadyDeps.includes(dep));
				logger.safeInfoWithoutCustomerData(
					'spa-apps.dashboard.common',
					`globals [${formatGlobals(newReadyDeps)}] ready after ${
						(Date.now() - this.#startTime) / 1000
					}s, missing globals [${formatGlobals(this.missingDeps)}]`,
				);
			}

			if (this.missingDeps.length === 0) {
				this.isReady = true;
				this.destroy();
				this.dispatchEvent(new CustomEvent('onReady'));
				logger.safeInfoWithoutCustomerData(
					'spa-apps.dashboard.common',
					`all globals [${formatGlobals(DEPENDENCIES)}] ready after ${
						(Date.now() - this.#startTime) / 1000
					}s`,
				);
			}
		}, pollingMs);

		this.#timeoutID = setTimeout(() => {
			this.destroy();
			if (!this.isReady) {
				this.isTimedOut = true;
				this.dispatchEvent(new CustomEvent('onTimeout'));
				logger.safeErrorWithoutCustomerData(
					'spa-apps.dashboard.common',
					`timed out polling globals, missing globals [${formatGlobals(this.missingDeps)}]`,
				);
			}
		}, timeoutMs);
	};

	destroy = () => {
		if (this.#intervalID != null) {
			clearInterval(this.#intervalID);
			this.#intervalID = null;
		}
		if (this.#timeoutID != null) {
			clearTimeout(this.#timeoutID);
			this.#timeoutID = null;
		}
	};
}
