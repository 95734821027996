import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	gadgetDeprecationTitleV1: {
		defaultMessage: "We're no longer supporting five of your gadgets",
		id: 'dashboard-internal-common.common.gadget.deprecation-banner-v1.gadget-deprecation-title.non-final',
		description:
			'Title of the deprecation message to be displayed to the user about gadget deprecation.',
	},
	gadgetDeprecationMessageV1: {
		defaultMessage:
			"Starting December 9, 2024, we're removing the functionality of the Jira Road Map, Bubble Chart, Heat Map, Projects, and Labels gadgets. If you don't delete them before then, they'll no longer work, and you won't be able to reference them for the available alternatives. <Link>More about the gadgets and alternative options</Link>",
		id: 'dashboard-internal-common.common.gadget.deprecation-banner-v1.gadget-deprecation-message.non-final',
		description:
			'An information section message to be displayed to the user about gadget deprecation.',
	},
	gadgetDeprecationMessageDismissV1: {
		defaultMessage: 'Dismiss',
		id: 'dashboard-internal-common.common.gadget.deprecation-banner-v1.gadget-deprecation-message-dismiss.non-final',
		description: 'Label for button to dimiss the gadget deprecation banner.',
	},
});
