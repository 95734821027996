import React, { useCallback, type ReactElement } from 'react';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { REFRESH_LABEL_UPDATE_INTERVAL } from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { GadgetData, GadgetDisplay } from '@atlassian/jira-dashboard-common/src/types.tsx';
import { CopyLinkButton } from '@atlassian/jira-dashboard-common/src/ui/copy-link-button/index.tsx';
import { DeprecationTooltip } from '@atlassian/jira-dashboard-common/src/ui/deprecation-tooltip';
import { isDeprecatedGadget } from '@atlassian/jira-dashboard-common/src/utils/is-deprecated-gadget';
import { useDashboardAnalytics } from '@atlassian/jira-dashboard-internal-common/src/controllers/dashboard-analytics/main.tsx';
import { GadgetReadOnlyController } from '@atlassian/jira-dashboard-internal-common/src/controllers/gadget-readonly/index.tsx';
import { GadgetControllerContextConsumer } from '@atlassian/jira-dashboard-internal-common/src/controllers/gadget/context.tsx';
import { GadgetInner } from '@atlassian/jira-dashboard-internal-common/src/ui/gadget/gadget-inner/main.tsx';
import { GadgetLayoutWithAnalytics } from '@atlassian/jira-dashboard-internal-common/src/ui/gadget/main.tsx';
import { GadgetTitleWrapper } from '@atlassian/jira-dashboard-internal-common/src/ui/gadget/styled.tsx';
import { TitleReadOnly as GadgetTitleReadOnly } from '@atlassian/jira-dashboard-internal-common/src/ui/gadget/title-readonly/index.tsx';
import { GadgetToolbar } from '@atlassian/jira-dashboard-internal-common/src/ui/gadget/toolbar/index.tsx';
import { LastRefreshed } from '@atlassian/jira-dashboard-internal-common/src/ui/last-refreshed/index.tsx';
import { isConnectGadget } from '@atlassian/jira-dashboard-internal-common/src/utils/gadget-type.tsx';
import type { useConnectGadgetTitles } from '@atlassian/jira-dashboard-internal-common/src/utils/use-gadget-title/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { UI_EVENT_TYPE } from '@atlassian/jira-product-analytics-bridge';

export type GadgetReadOnlyProps = {
	dashboardId: string;
	data: GadgetData;
	display: GadgetDisplay;
	unconfiguredFallback: ReactElement;
	refreshId: number;
	onMaximizeOrRestore: (arg1: boolean) => void;
	onRegisterConnectGadget: ReturnType<typeof useConnectGadgetTitles>['registerConnectGadget'];
	onUnregisterConnectGadget: ReturnType<typeof useConnectGadgetTitles>['unregisterConnectGadget'];
};

const noop = () => undefined;

export const GadgetReadOnly = ({
	dashboardId,
	data,
	display,
	unconfiguredFallback,
	refreshId,
	onRegisterConnectGadget,
	onUnregisterConnectGadget,
	onMaximizeOrRestore,
}: GadgetReadOnlyProps) => {
	const { id, isMaximizable, titleUrl, forge } = data;
	const isFirstPartyGadget = !isConnectGadget(data) && !forge;
	const fireAnalytics = useDashboardAnalytics();

	const onCopyLinkHandler = useCallback(() => {
		fireAnalytics({
			eventType: UI_EVENT_TYPE,
			action: 'clicked',
			actionSubject: 'dashboard.copy-link-button',
			actionSubjectId: 'gadget',
		});
	}, [fireAnalytics]);

	const isDeprecated = isDeprecatedGadget(data.amdModule);

	return (
		<GadgetReadOnlyController data={data} display={display} dashboardRefreshId={refreshId}>
			{/* TODO - these are also in context so should eventually can be wired more directly */}
			<GadgetControllerContextConsumer>
				{({
					isDisplayed,
					isMaximized,
					isMinimized,
					onMinimizeOrRestore,
					shouldUseCache,
					isLoading,
					setIsLoading,
					lastRefreshed,
					isUnconfigured,
					title,
					setDefaultTitle,
					onRenameSave,
				}) => (
					<GadgetLayoutWithAnalytics
						gadgetData={data}
						isDisplayed={isDisplayed}
						isContentHidden={isMinimized}
						header={
							<Flex alignItems="center">
								<GadgetTitleWrapper id={`gadget-title-${id}`}>
									{isDeprecated && fg('endeavour_gadget_deprecation_banner_v1') ? (
										<Box xcss={titleStyles}>
											<GadgetTitleReadOnly
												title={title}
												titleUrl={titleUrl}
												forgeEnvironment={forge?.environment}
												forgeEnvironmentKey={forge?.environmentKey}
											/>
											<DeprecationTooltip />
										</Box>
									) : (
										<GadgetTitleReadOnly
											title={title}
											titleUrl={titleUrl}
											forgeEnvironment={forge?.environment}
											forgeEnvironmentKey={forge?.environmentKey}
										/>
									)}
								</GadgetTitleWrapper>
								<GadgetToolbar
									gadgetId={id}
									title={title}
									onMinimizeOrRestore={onMinimizeOrRestore}
									isMinimized={isMinimized}
									isMaximizable={isMaximizable}
									onMaximizeOrRestore={onMaximizeOrRestore}
									isMaximized={isMaximized}
									additionalToolbarItems={
										isMaximizable && (
											<CopyLinkButton
												dashboardId={dashboardId}
												gadgetId={id}
												onCopyClick={onCopyLinkHandler}
											/>
										)
									}
								/>
							</Flex>
						}
						footer={
							isFirstPartyGadget && (
								<LastRefreshed
									lastRefreshed={lastRefreshed}
									updateInterval={REFRESH_LABEL_UPDATE_INTERVAL}
								/>
							)
						}
					>
						<div data-testid="dashboard-view.ui.gadget-readonly.gadget-wrapper">
							<GadgetInner
								dashboardId={dashboardId}
								data={data}
								isUnconfigured={isUnconfigured}
								isEditModeEnabled={false}
								isLoading={isLoading}
								isInEditMode={false}
								shouldUseCache={shouldUseCache}
								setIsLoading={setIsLoading}
								setDefaultTitle={setDefaultTitle}
								onEditModeCancel={noop}
								isMaximized={isMaximized}
								unconfiguredFallback={unconfiguredFallback}
								onRegisterConnectGadget={onRegisterConnectGadget}
								onUnregisterConnectGadget={onUnregisterConnectGadget}
								setTitle={onRenameSave}
							/>
						</div>
					</GadgetLayoutWithAnalytics>
				)}
			</GadgetControllerContextConsumer>
		</GadgetReadOnlyController>
	);
};

const titleStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
});
