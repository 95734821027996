import React, { useState, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import LinkIcon from '@atlaskit/icon/glyph/link';
import FieldCopyText from '@atlassian/jira-common-components-field-copy-text/src/view.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { MAXIMIZED_PARAM } from '../../constants';
import messages from './messages';

export type Props = {
	dashboardId: string;
	gadgetId?: string;
	onCopyClick?: (e: SyntheticEvent, event: UIAnalyticsEvent) => void;
};

export const CopyLinkButton = ({ dashboardId, gadgetId, onCopyClick }: Props) => {
	const { formatMessage } = useIntl();
	const [isCopied, setIsCopied] = useState<boolean>(false);

	if (__SERVER__) {
		return;
	}

	const label = formatMessage(messages.copyTextLabel);
	const tooltip = isCopied ? formatMessage(messages.copiedLabel) : label;
	const id = gadgetId ? `${dashboardId}-${gadgetId}` : dashboardId;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const { protocol, host, pathname } = window.location;
	const url = gadgetId
		? `${protocol}//${host}${pathname}?${MAXIMIZED_PARAM}=${gadgetId}`
		: `${protocol}//${host}${pathname}`;

	return (
		<FieldCopyText
			id={id}
			label={label}
			shouldFitContainer
			tooltipDelay={0}
			tooltipLabel={tooltip}
			tooltipLabelPosition="bottom"
			value={url}
			hideFieldText
			onCopyClick={onCopyClick}
			onClickChange={(status) => setIsCopied(status)}
			onCopyReset={() => setIsCopied(false)}
		>
			<Button appearance="subtle" aria-label={label} iconBefore={<LinkIcon label={label} />} />
		</FieldCopyText>
	);
};
