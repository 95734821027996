import { useCallback, type ComponentType } from 'react';
import type {
	ReactGadgetProps,
	SetterOrState,
} from '@atlassian/jira-react-gadgets-common/src/types.tsx';
import GADGETS_DEFINITION, {
	type ReactGadgetState,
	type ReactKey,
} from '@atlassian/jira-react-gadgets-definition/src/main.tsx';
import { useDashboardReactGadgetsResource } from '@atlassian/jira-router-resources-dashboard';
import { lensReactGadget } from '../../../../utils/react-gadget-lenses/index.tsx';

export type UseReactGadgetStateResult = {
	state: ReactGadgetState | Error | null;
	setState: (arg1: SetterOrState<ReactGadgetState>) => void;
	componentView: ComponentType<ReactGadgetProps<ReactGadgetState>>;
	componentEdit?: ComponentType<ReactGadgetProps<ReactGadgetState>>;
};

export const useReactGadgetState = (id: string, reactKey: ReactKey): UseReactGadgetStateResult => {
	const { data, update, loading, error } = useDashboardReactGadgetsResource();
	const reactGadgetsState = data?.reactGadgetsState ?? null;

	const state = (() => {
		if (reactGadgetsState != null && id in reactGadgetsState) {
			return reactGadgetsState[id];
		}

		if (loading) {
			return null;
		}

		if (reactGadgetsState == null) {
			return error ?? new Error('Gadget resource not initialised');
		}

		return new Error(
			`Gadget state not found in initialised resource for gadget with reactKey: "${reactKey}" and ID: "${id}"`,
		);
	})();

	const { componentView, componentEdit } = GADGETS_DEFINITION[reactKey];

	const setState = useCallback<(arg1: SetterOrState<ReactGadgetState>) => void>(
		(setterOrValue: SetterOrState<ReactGadgetState>) => {
			if (reactGadgetsState && id in reactGadgetsState) {
				update(lensReactGadget(id, setterOrValue));
			}
		},
		[update, reactGadgetsState, id],
	);

	return {
		state,
		componentView,
		componentEdit,
		setState,
	};
};
