import { useEffect } from 'react';
import { type FlagConfiguration, toFlagId, useFlagsService } from '@atlassian/jira-flags';
import { useRouterActions } from '@atlassian/react-resource-router';
import { clickedWithShortcutKeys } from '../utils/events/index.tsx';
import messages from './messages';

export const MAX_URL_LENGTH = 3984;

// Absolutely exclude any paths that contain the following regex
const EXCLUDED_PATHS = [/^\/rest\/(?:gadget|api)\/1\.0\//, /^\/plugins\/servlet\/gadgets\/ifr/];

const isExcludedPath = (path: string) => EXCLUDED_PATHS.some((regex) => regex.test(path));

// Anchor tags inside gadgets uses href to navigate to another page. This hook is to listen to
// the clicks on anchor tags and navigate using RRR's push method for spa transition.
export const useLinkInterceptor = (
	element: HTMLElement | Document | null | undefined,
	isLoading: boolean,
) => {
	const { push } = useRouterActions();
	const { showFlag } = useFlagsService();

	useEffect(() => {
		if (isLoading) {
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		const linkClickHandler = (event: Event) => {
			const anchor =
				event.target instanceof HTMLAnchorElement
					? event.target
					: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						(event.target as Element).closest?.('a');

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			if (anchor == null || window.location.hostname !== anchor.hostname) {
				// skip if it is not an anchor or to external link
				return;
			}
			if (anchor.href && anchor.href.length > MAX_URL_LENGTH) {
				event.preventDefault();
				const flagConfig: FlagConfiguration = {
					key: toFlagId('urlTooLongWarning'),
					type: 'error',
					title: messages.linkInterceptorUrlTooLongTitle,
					description: messages.linkInterceptorUrlTooLongDescription,
				};
				showFlag(flagConfig);
			} else if (
				anchor.pathname &&
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				!clickedWithShortcutKeys(event as MouseEvent) &&
				// some links from the monolith code are invalid and calls
				// event.preventDefault (e.g. popups), so we filter those out
				!event.defaultPrevented &&
				!isExcludedPath(anchor.pathname)
			) {
				event.preventDefault();
				push(anchor.href);
			}
		};
		element?.addEventListener('click', linkClickHandler);

		return () => {
			element?.removeEventListener('click', linkClickHandler);
		};
	}, [element, isLoading, push, showFlag]);
};
