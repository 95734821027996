import React, { useCallback, useLayoutEffect } from 'react';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { Stack, Text } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { GADGET_METRICS_TYPE } from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { GadgetMetricsEventType } from '@atlassian/jira-dashboard-common/src/types.tsx';
import { GadgetFallback } from '@atlassian/jira-dashboard-internal-common/src/common/gadget/gadget-fallback/index.tsx';
import { useMessageBus } from '@atlassian/jira-dashboard-internal-common/src/controllers/message-bus/message-bus.tsx';
import {
	GADGET_RENDER_CONTAINER_EVENT,
	FALLBACK_GADGET_SOURCE,
} from '@atlassian/jira-dashboard-internal-common/src/controllers/metrics/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state/index.tsx';
import messages from './messages';

export const UnconfiguredGadgetFallback = ({
	id,
	hasEditPermissions,
}: {
	id: string;
	hasEditPermissions: boolean;
}) => {
	const { formatMessage } = useIntl();
	const [, { broadcastMessage: broadcastMessageBusListener }] = useMessageBus();
	const [{ currentPageId }] = useSpaStateTransition();

	const reportGadgetMetrics = useCallback(
		(eventType: GadgetMetricsEventType) => {
			broadcastMessageBusListener(
				GADGET_METRICS_TYPE.REPORT,
				{
					eventType,
					contentType: 'Unconfigured',
					source: FALLBACK_GADGET_SOURCE,
				},
				{ gadgetId: id, pageId: currentPageId },
			);
		},
		[broadcastMessageBusListener, id, currentPageId],
	);

	useLayoutEffect(() => {
		reportGadgetMetrics(GADGET_RENDER_CONTAINER_EVENT);
	}, [reportGadgetMetrics]);

	return (
		<GadgetFallback
			icon={
				<SettingsIcon
					label={formatMessage(messages.settingsIconLabel)}
					primaryColor={token('color.icon', colors.N500)}
				/>
			}
			id={id}
		>
			<Stack space="space.150">
				<Text size="UNSAFE_small" weight="semibold" color="color.text.accent.gray">
					{formatMessage(messages.mainMessage)}
				</Text>
				<Text size="UNSAFE_small" weight="semibold" color="color.text.accent.gray">
					{formatMessage(
						hasEditPermissions
							? messages.instructionsWithEditPermissions
							: messages.instructionsWithoutEditPermissions,
					)}
				</Text>
			</Stack>
		</GadgetFallback>
	);
};
